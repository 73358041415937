.container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .new-activity-form input,
  .new-activity-form button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .table-responsive {
    overflow-x: auto;
  }
  
  @media (max-width: 768px) {
    .table-responsive {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }

  .excel-like-table td, .excel-like-table th {
    height: 5px ;
    padding: 20px; /* Adjust padding to match Excel's appearance */
  }
  
  /* Adjustments for tbody to make it more compact */
.excel-like-table tbody tr td {
  padding: 2px 4px; /* Reduce padding */
  font-size: 13px; /* Optional: Adjust font size for content to fit better */
  line-height: 16px; /* Adjust line height to match the compact style */
}

/* You may also want to adjust the header to align with the tbody's new compact style */
.excel-like-table thead tr th {
  padding: 4px; /* Keep header padding slightly larger for visual hierarchy */
  font-size: 14px; /* Adjust if needed to match tbody font size */
}
/* ActivityTable.css */
.btn-extra-sm {
  padding: 0.25rem 0.5rem; /* Smaller padding */
  font-size: 0.75rem; /* Smaller font size */
  line-height: 1.5; /* Adjust line height if necessary */
  border-radius: 0.2rem; /* Adjust border radius for aesthetics */
}

/* Continuing from .btn-primary styles */

.btn-secondary {
  background-color: #6c757d; /* Bootstrap secondary color */
  color: white;
}

.btn-danger {
  background-color: #dc3545; /* Bootstrap danger color */
  color: white;
}

/* Ensure buttons have a consistent appearance when disabled */
.btn-primary.disabled, .btn-secondary.disabled, .btn-danger.disabled {
  opacity: 0.65;
}

/* Add a hover effect for better user interaction feedback */
.btn-primary:hover, .btn-secondary:hover, .btn-danger:hover {
  opacity: 0.85;
}

/* Focus and active states for better accessibility and user experience */
.btn-primary:focus, .btn-primary:active,
.btn-secondary:focus, .btn-secondary:active,
.btn-danger:focus, .btn-danger:active {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* Adjust the box-shadow color based on the button type */
.btn-secondary:focus, .btn-secondary:active {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-danger:focus, .btn-danger:active {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

/* ActivityTable.css */
.btn-extra-sm:not(:last-child) {
  margin-right: 8px; /* Adjust the spacing as needed */
}

.me-auto{
  color:'white'
}

  