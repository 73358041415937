/* QueryEditor.css */
textarea {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  Button {
    margin-right: 10px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
  }
  
  /* QueryEditor.css */
.custom-padding {
    padding: 10px 20px; /* Example padding, adjust as needed */
    margin:10px 20px;
  }
  .editor-container {
    border-radius: 10px; /* Adjust as needed */
    overflow: hidden; /* Ensures the border radius applies to the editor's content as well */
    border: 1px solid #ccc; /* Optional: adds a border around the editor */
  }
  
  /* Custom CSS for a more compact table */
.compact-table th, 
.compact-table td {
  padding: 5px; /* Reduced padding */
  font-size: 12px; /* Smaller font size */
}

.compact-table .accordion-button {
  padding: 0.2rem 1rem; /* Adjust padding for accordion headers */
  font-size: 12px; /* Match font size with table cells */
}

.compact-table .accordion-body {
  padding: 0.5rem 1rem; /* Adjust padding for accordion bodies */
}


  /* button:hover {
    background-color: #0056b3;
  } */
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    text-align: left;
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  /* QueryEditor.css */
.dbTable {
    margin-bottom: 20px;
  }
  
  .dbTable strong {
    display: block;
    margin-bottom: 5px;
  }
  
  .dbTable ul {
    list-style-type: none;
    padding: 0;
  }
  /* QueryEditor.css */
button, a {
    padding: 10px; /* Adjust the padding value as needed */
    margin: 5px; /* Optional: adds a little space around buttons and links */
  }
  
  /* If you want to specifically target React Bootstrap buttons */
  .btn {
    padding: 10px; /* Adjust padding for React Bootstrap buttons */
  }
  
  /* For links, specifically if you want to target the CSVLink component */
  .csv-link-classname {
    padding: 10px; /* Adjust padding */
    /* Add any additional styling here */
  }
  
  
  